import React, { useState } from 'react'
import { Message, Modal, Progress, Button } from '@yisa/webui';
import { ajax } from '@/utils';
import { ExclamationCircleFilled } from '@yisa/webui/es/Icon';
import './index.scss'

/**
 * @param visible modal显示隐藏
 * @param formData 导出数据
 * @param url 导出url
 * @param callback 回调
 * @param ajaxType 导出ajax方式
 * @param total 数据总量
 * @param onCancel 关闭弹框事件
 * */

export default function ExportModal(props) {
  const { visible, formData, title = "导出", url = "", callback, ajaxType = "GET", total = 0, onCancel = () => { } } = props

  const [exporting, setExporting] = useState(false) // 正在导出
  const [percent, setPercent] = useState(0)

  const startExport = () => {
    if (!url) {
      Message.error("请传入导出链接")
      return false
    }
    setExporting(true)
    ajax({
      url: url,
      method: ajaxType,
      params: formData
    }).then(res => {
      const { status, message: msg, data } = res
      if (status === 20000) {
        setPercent(100)
        setTimeout(() => {
          window.open(data.url)
          onClose()
          Message.success('导出成功')
        }, 400)
        if (callback) {
          callback(data)
        }
      } else {
        Message.error(msg)
      }
    }).catch(err => console.log(err))
  }

  const onClose = () => {
    setExporting(false)
    setPercent(0)
    onCancel()
  }


  return (
    <Modal visible={visible} onCancel={onClose} className="export-modal" footer={null} closable={false} width={480}>
      {
        !exporting ? <div className='info'>
          <main>
            {/* <ExclamationCircleFilled /> */}
            <ExclamationCircleFilled />
            <p>共 <em>{total}</em> 条信息，是否全部{title}?</p>
            {/* <p>是否导出当前数据?</p> */}
          </main>
          <footer>
            <Button onClick={onClose} className='yisa-btn-reset'>取消</Button>
            <Button onClick={startExport} type='primary' className="yisa-btn-primary">确认</Button>
          </footer>
        </div> : ""
      }
      {
        exporting ? <div className='progress'>
          <p>正在导出...</p>
          <Progress size="large" percent={percent} showInfo={false} />
        </div> : ""
      }
    </Modal>
  )
}

import React, { useState, useEffect, useRef } from 'react';
import { Button, Pagination, Message, Modal, Upload,Loading } from '@yisa/webui';
import { ResultFlow, FormInput, FormTreeSelect, FormSelect } from '@/components';
import { ErrorImage } from '@yisa/webui_business';
import { useBoolean } from "ahooks";
import { character } from "@/utils";
import ajax from '@/services';
import { getToken } from '@/utils'
import { CloseOutlined, CertificateOutlined, HomeOutlined } from '@yisa/webui/es/Icon';
import './index.scss'

function PersonArchives(props) {

  // 原始表单
  const defaultFormData = {
    // 姓名
    name: "",
    // 身份证号
    id_card: "",
    // 所属单位
    business_id: [],
    // 页码
    page: 1,
    // 数据量
    page_size: character.pageSizeOptions[0],
  }

  // 编辑弹窗
  const [editVisible, setEditVisible] = useState({
    visible: false,
    data: {}
  })

  const [formData, setFormData] = useState(defaultFormData)
  const [lastFormData, setLastFormData] = useState(formData)
  const [loading, { setTrue, setFalse }] = useBoolean(false)
  // 经营单位列表
  const [businessList, setBusinessList] = useState([])
  const [modalLoad, setModalLoad] = useState(false)
  // 表单修改
  const changeValue = (v, k) => {
    setFormData(Object.assign({}, formData, { [k]: v }))
  }
  const [exportVisible, setExPortVisible] = useState({
    visible: false,
    data: {}
  })
  const [fileList, setFileList] = useState([])
  const [resultData, setResultData] = useState({
    list: [],
    total: 0
  })
  // 删除弹窗
  const [delVisible, setDelVisible] = useState({
    visible: false,
    data: {}
  })
  // 删除用户
  const delUser = (id) => {
    ajax.employeeDel({ id: delVisible.data.id }).then(res => {
      if (res.status === 20000) {
        Message.success(res.message)
        setDelVisible({
          visible: false,
          data: {}
        })
        getList(defaultFormData)
      } else {
        Message.error(res.message)
      }
      setFalse(false)
    }).catch(err => console.log(err))

  }
  // 获取列表数据
  const getList = (params = formData) => {
    setTrue(true)
    setLastFormData(params)
    ajax.getEmployeeList(params).then(res => {
      if (res.status === 20000) {
        setResultData(res.data)
      } else {
        Message.error(res.message)
      }
      setFalse(false)
    }).catch(err => console.log(err))
  }
  // 获取接收部门
  const getBusinessList = () => {
    ajax
      .getBusiness()
      .then((res) => {
        const { status, message, data } = res;
        if (status === 20000) {
          let arr = [{ label: "全部", value: "-1", children: data }]
          setBusinessList(arr);
        } else {
          Message.error(message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const employeeAdd = () => {
    let obj = {
      face_image: editVisible.data.face_image,
      name: editVisible.data.name,
      id_card: editVisible.data.id_card,
      business_name: editVisible.data.business_name,
    }
    if (!obj.face_image || !obj.id_card || !obj.name || !obj.business_name) return Message.warning("请填写必填项！")

    ajax.employeeAdd(obj).then(res => {
      if (res.status === 20000) {
        setEditVisible({
          visible: false,
          data: {}
        })
        setFileList([])
        getList(lastFormData)
      } else {
        Message.error(res.message)
      }
      setFalse(false)
    }).catch(err => console.log(err))
  }


  useEffect(() => {
    getList(formData)
    getBusinessList()
  }, [])

  const reset = () => {
    setFormData(defaultFormData)
    getList(defaultFormData)
  }
  const handleBeforeUpload = (file, filesList) => {
    setModalLoad(true)
    if (file?.size > 200 * 1024 * 1024) {
      Message.error('请上传小于200M的文件')
      setModalLoad(false)
      return false
    }
  }
  return <div className="person-archives-page">
    <div className="search scroll-bar">
      <div className="title">查询条件</div>

      <FormInput
        className="search-item"
        yisaLabel="姓名"
        placeholder="请输入姓名"
        value={formData.name}
        onChange={(v) => changeValue(v.target.value, "name")}
      />
      <FormInput
        className="search-item"
        yisaLabel="身份证号"
        placeholder="请输入身份证号"
        value={formData.id_card}
        onChange={(v) => changeValue(v.target.value, "id_card")}
      />
      <FormTreeSelect
        labelPosition="top"
        yisaLabel="所属单位"
        placeholder="请选择所属单位"
        className="search-item"
        value={formData.business_id}
        onChange={(v) => changeValue(v, "business_id")}
        fieldNames={{ title: "label", key: "value" }}
        treeData={businessList}
      />

      <div className="search-btn-box">
        <Button className="reset-btn" onClick={reset}>
          重置
        </Button>
        <Button type="primary" className='search-btn' onClick={() => getList(formData)}>
          搜索
        </Button>

      </div>
    </div>
    <div className="table-box">
      <nav className="total">
        <p>
          共<span> {resultData.total || 0} </span>条结果
        </p>
        <Button className="add-user-btn" type="primary" onClick={() => setEditVisible({
          visible: true,
          data: {}
        })}>
          新增人员
        </Button>
      </nav>

      <ResultFlow
        loading={loading}
        resultData={resultData.list}
      >
        <div className="list" >
          {
            resultData.list.map((item, index) => {
              return <div className="person-card" key={index}>
                <div className="top">
                  <div className="name" title={item.name}>{item.name}</div>
                  <CloseOutlined onClick={() => {
                    setDelVisible({
                      visible: true,
                      data: {
                        id: item.id,
                      }
                    })
                  }} />
                </div>
                <div className="info">
                  <CertificateOutlined />
                  <div className="id-card" title={item.id_card}>{item.id_card}</div>
                </div>
                <div className="info">
                  <HomeOutlined />
                  <div className="id-card" title={item.depart_name}>{item.depart_name}</div>
                </div>
                <ErrorImage wrapperStyle={{ width: '198px', height: '250px' }} src={item.img_url} />
              </div>
            })
          }
        </div>
        <Pagination
          className="pagination-common"
          showQuickJumper
          showSizeChanger
          size='default'
          position={['bottomCenter']}
          current={lastFormData.page}
          total={resultData.total}
          pageSize={lastFormData.page_size}
          onChange={(page, pageSize) => {
            let obj = {};
            if (pageSize !== lastFormData.page_size) {
              obj.page = 1
              obj.page_size = pageSize
            } else {
              obj.page = page
            }
            let newParams = Object.assign({}, lastFormData, obj);
            getList(newParams)
          }}
          pageSizeOptions={character.pageSizeOptions}
        />

      </ResultFlow>
    </div>
    <Modal
      title="添加用户"
      className='person-modal'
      visible={editVisible.visible}
      width={514}
      loading={modalLoad}
      maskClosable={false}
      okText="提交"
      cancelText="取消"
      onOk={() => {
        employeeAdd()
      }}
      onCancel={() => {
        setEditVisible({
          visible: false,
          data: {}
        })
        setFileList([])
      }}
    >
      <div className="save-content">
        <div className="title-name">单个人员信息录入</div>
        <div className="search-box">
          <div className="left">
            <div className="cc-form-name">
              <label className="required">*</label>
              <label>证件照</label>
            </div>
            <Upload
              multiple
              accept="image/*"
              tip=''
              className="upload-picture"
              limit={1}
              headers={{ 'Authorization': `${getToken()}` }}
              listType="picture-card"
              action="/api/v1/employee/upload"
              fileList={fileList}
              onChange={(fileList, file) => {

                const { status, response } = file
                if (status === 'done') {
                  if (!response) {
                    return setEditVisible({
                      visible: true,
                      data: {
                        ...editVisible.data,
                        face_image: ""
                      }
                    })
                  }
                  const { status, data } = response;
                  if (status === 20000) {
                    if (data.url) {
                      setEditVisible({
                        visible: true,
                        data: {
                          ...editVisible.data,
                          face_image: data.url
                        }
                      })
                    } else {
                      Message.error("获取图片地址失败")
                    }
                  } else {
                    Message.error(response.message)
                  }
                }
                setFileList(fileList)
              }} />
          </div>

          <div className="right">
            <FormInput
              className="search-item"
              yisaLabel="姓名"
              placeholder="请输入姓名"
              isRequired
              value={editVisible.data.name}
              onChange={(v) => setEditVisible({
                visible: true,
                data: {
                  ...editVisible.data,
                  name: v.target.value
                }
              })}
            />
            <FormInput
              className="search-item"
              yisaLabel="身份证号"
              placeholder="请输入身份证号"
              isRequired
              value={editVisible.data.id_card}
              onChange={(v) => setEditVisible({
                visible: true,
                data: {
                  ...editVisible.data,
                  id_card: v.target.value
                }
              })}
            />
            <FormSelect
              labelPosition="top"
              yisaLabel="所属单位"
              isRequired
              showClose
              options={businessList[0]?.children || []}
              className="search-item"
              placeholder="请选择所属单位"
              value={editVisible.data.business_name}
              onChange={(v) => setEditVisible({
                visible: true,
                data: {
                  ...editVisible.data,
                  business_name: v
                }
              })}
            />
          </div>


        </div>
        <div className="title-name">批量导入</div>
        <div className="up-file-box">
          <Upload
            className="upload-file"
            action="/api/v1/employee/import"
            tip=''
            showUploadList={false}
            multiple
            headers={{ 'Authorization': `${getToken()}` }}
            accept={['.zip', '.7z']}
            beforeUpload={handleBeforeUpload}
            onChange={(data, e) => {
              if (e.status === "done") {
                setModalLoad(false)
                if (e?.response.data.url) {
                  // Message.warning(e?.response.message)
                  setExPortVisible({
                    visible: true,
                    data: e?.response
                  })
                } else {
                  Message.warning(e?.response.message)
                }
              }
            }}
          >
            批量导入
          </Upload>
          <Loading spinning={modalLoad} />
          <a
            className="ant-btn-primary"
            href={window.sysConfig.zip_file}
            target="_blank"
          >
            下载模板
          </a>
        </div>

      </div>
    </Modal>
    <Modal
      className='del-modal'
      visible={delVisible.visible}
      width={420}
      maskClosable={false}
      okText="确认"
      cancelText="取消"
      onOk={() => {
        delUser()
      }}
      onCancel={() => {
        setDelVisible({
          visible: false,
          data: {}
        })
      }}
    >
      <div className="total">是否确认删除？</div>
      <div className="title">请确认是否删除该人员信息？</div>

    </Modal>
    <Modal
      title="导入结果"
      hideCancel={true}
      className='export-user-modal'
      visible={exportVisible.visible}
      width={650}
      maskClosable={false}
      okText="取消"
      onOk={() => {
        setEditVisible({
          visible: false,
          data: {}
        })
        setExPortVisible({
          visible: false,
          data: {}
        })
        getList(formData)
      }}
      onCancel={() => {
        getList(formData)
        setExPortVisible({
          visible: false,
          data: {}
        })
      }}
    >
      <div className="export-user-modal-content">

        <div className="title">{exportVisible.data.message}</div>
        {
          exportVisible.data.data && exportVisible.data.data.err_file_url ? <a
            className="ant-btn-primary"
            href={exportVisible.data.data.err_file_url}
            target="_blank"
          >
            失败数据下载
          </a> : null
        }

      </div>
    </Modal>
  </div>
}

export default PersonArchives

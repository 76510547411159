import qs from 'qs';
import { Message } from '@yisa/webui';

const deepClone = (source, dest) => {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'shallowClone');
  }
  dest = dest || (source.constructor === Array ? [] : {});
  for (const key in source) {
    if (Object.prototype.hasOwnProperty.call(source, key)) {
      if (source[key] && typeof source[key] === 'object') {
        dest[key] = source[key].constructor === Array ? [] : {};
        deepClone(source[key], dest[key]);
      } else {
        dest[key] = source[key];
      }
    }
  }
  return dest;
};

export function getRoiData(list, isShipAlgo) {
  const roiTemp = initDrawDataTemp(list);

  const { points } = transformConfigdataWKTToJs(list, isShipAlgo);

  return { points, roiTemp };
}
export function list2Map(arr = [], key = 'key', target) {
  const map = {};
  arr.forEach((item) => {
    if (typeof item[key] !== 'undefined') {
      map[item[key]] = target ? item[target] : item;
    } else {
      console.error(`key: ${key}不存在`, arr);
    }
  });
  return map;
}

export function traverTree(tree1 = [], childrenKey = 'children', fn) {
  return trave(tree1);
  function trave(tree, parent = null) {
    tree.forEach((item, index) => {
      const _item = fn(item, parent, index);
      typeof _item !== 'undefined' && (tree[index] = _item);
      if (item[childrenKey] && item[childrenKey].length) {
        trave(item[childrenKey], item);
      }
    });
    return tree;
  }
}

export function flattenTree(data, childrenKey = 'children') {
  return data.reduce(
    (result, next) =>
      result.concat({ ...next }, ...(Array.isArray(next[childrenKey]) ? flattenTree(next[childrenKey]) : [])),
    [],
  );
}

//  禁止中文输入
const stopChinese = (val) => {
  return val.replace(/[\u4E00-\u9FA5]/gi, '');
};

const wktToJs = (wkt) => {
  if (!wkt) {
    return [];
  }

  const reg = /\((.+?)\)/g;
  const arr = [];
  if (reg.test(wkt)) {
    wkt = RegExp.$1.replace('(', '');
    const wktArr = wkt.split(',');
    wktArr.forEach((item) => {
      const newArr = item.trim().split(' ');
      const obj = {
        x: newArr[0],
        y: newArr[1],
      };
      arr.push(obj);
    });

    return arr;
  }
};

// 点线框  线字段（line）转 linestring
const lineToLineString = (type) => {
  return type === 'line' ? 'linestring' : type;
};

const jsToWkt = (btnConfigs, data, isReName = false) => {
  const obj = {};
  const strObj = {
    point: 'POINT(',
    linestring: 'LINESTRING(',
    polygon: 'POLYGON((',
  };
  data.forEach((el) => {
    const type = lineToLineString(el.type);
    const pointsArr = el.points;
    let startStr = strObj[type];
    const endStr = type === 'polygon' ? '))' : ')';
    const pointLen = pointsArr.length;

    pointsArr.forEach((point, index) => {
      const x = Number(point.x).toFixed(5);
      const y = Number(point.y).toFixed(5);
      startStr += `${x} ${y}${index === pointLen - 1 ? '' : ','}`;
    });

    startStr += endStr;
    if (!obj[el.key]) obj[el.key] = [];
    const temp = isReName ? JSON.stringify({ name: el.text, polygon: startStr }) : startStr;
    obj[el.key].push(temp);
  });

  return btnConfigs.map((item) => {
    return {
      ...item,
      value: JSON.stringify(obj[item.key]) || [],
    };
  });
};

//  浏览器全屏
const fullScreen = () => {
  const el = document.documentElement;

  const rfs = el.requestFullScreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullScreen;

  if (typeof rfs !== 'undefined' && rfs) {
    rfs.call(el);
  }
};

const cancelFullScreen = () => {
  if (document.webkitCancelFullScreen) {
    document.webkitCancelFullScreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
};

//  列表分页 查询没有数据自动查询上一页  （非第一页）
const tableListIsCheckPrePage = (list, currentPage) => {
  if (currentPage === 1) return false;

  if (!list.length) {
    return currentPage - 1;
  }
};

//  事件绑定兼容
const addHandle = (dom, type, fn) => {
  if (document.addEventListener) {
    dom.addEventListener(type, fn, false);
  } else if (document.attachEvent) {
    dom.attachEvent(`on${type}`, fn);
  } else {
    const oldDomFun = dom[`on${type}`];
    dom[`on${type}`] = function () {
      oldDomFun();
      fn && fn();
    };
  }
};

//  事件解绑
const removeHandle = (dom, type, fn) => {
  if (document.removeEventListener) {
    dom.removeEventListener(type, fn);
  } else if (document.detachEvent) {
    dom.detachEvent(`on${type}`, fn);
  } else {
    dom[`on${type}`] = null;
  }
};
const getCssPrefix = () => {
  const styles = window.getComputedStyle(document.documentElement, '');
  const core = (Array.prototype.slice
    .call(styles)
    .join('')
    .match(/-(moz|webkit|ms|o)-/) ||
    (styles.OLink === '' && ['', 'o']))[1];
  return `-${core}-`;
};
//  判断浏览器前缀
const getBowserPrefix = () => {
  const prefix = {
    '-webkit-': 'webkit',
    '-moz-': 'moz',
    '-ms-': 'ms',
    '-o-': 'o',
  };

  return prefix[getCssPrefix()];
};

//  判断当前环境
const isDev = () => {
  return process.env.NODE_ENV !== 'production';
};

//  中英文获取语言 对应key的val key支持对象obj.info.name链式取值
const getLanguageVal = (store, key) => {
  const keys = key.split('.');
  let obj = JSON.parse(store.getState());
  const len = keys.length;

  for (let i = 0; i < len; i++) {
    const item = keys[i];
    obj = obj[item];
  }
  return obj;
};

//  中英文获取语言 对应key的val key支持对象obj.info.name链式取值 新函数
const getLangShowName = (key) => {
  const keys = key.split('.');
  let obj = window.lang;
  const len = keys.length;

  for (let i = 0; i < len; i++) {
    const item = keys[i];
    obj = obj[item];
  }
  return obj;
};

//  中英文字段显示
const langShowStr = (lang, key) => {
  key = lang !== 'ch' ? `${key}_${lang}` : key;
  // console.log('lang', key)
  return key;
};

//  下载文件    type--true 打开新空白页面下载
const downloadFile = (url, params) => {
  if (params) url = `${url}?${qs.stringify(params)}`;
  const downloadwin = window.open(url, '_blank');
};

const initDrawDataTemp = (temp) => {
  const initBtns = [];
  let initDrawType = '';

  temp.forEach((item, index) => {
    let { type } = item;
    type = type === 'linestring' ? 'line' : type;

    if (!index) {
      initDrawType = type;
    }
    initBtns.push({ ...item, type });
  });

  return {
    initBtns,
    initDrawType,
  };
};

//  点线框数据 wkt 转 js
const transformConfigdataWKTToJs = (data, isRename) => {
  const arr = [];

  const flag = data.length ? data[0]?.value[0]?.includes('name') : true;
  if (isRename && !flag) {
    Message.warning('接口返回的ROI数据格式错误');
    return [];
  }
  //  设置初始化点线框数据
  data.forEach((camera) => {
    const points = (isRename ? camera.value?.map((item) => JSON.parse(item)) : camera.value) || [];
    let { type } = camera;
    const { key } = camera;
    const text = camera.name;
    type = type === 'linestring' ? 'line' : type;

    points.forEach((item) => {
      const obj = {
        type,
        points: isRename ? wktToJs(item.polygon) : wktToJs(item),
        key,
        text: isRename ? item.name : text,
      };
      arr.push(obj);
    });
  });

  return arr;
};

// 点线框数据 wkt 转 js (重命名)
const reNameTransformConfigdataWKTToJs = (data) => {
  const arr = [];

  //  设置初始化点线框数据
  data.forEach((camera) => {
    const points = camera.value || [];
    let { type } = camera;
    const { key } = camera;
    const text = camera.name;
    type = type === 'linestring' ? 'line' : type;

    points.forEach((item) => {
      const temp = JSON.parse(item); // {name,polygon}
      const obj = {
        type,
        points: wktToJs(temp.polygon),
        key,
        text: temp.name,
      };
      arr.push(obj);
    });
  });
  return { points: arr };
};

//  获取url参数
const getUrlParam = (paramKey) => {
  let { search } = window.location;

  if (!search) return;

  search = search.substring(1);
  const arr = search.split('&');
  const obj = {};

  arr.forEach((item) => {
    const newArr = item.split('=');
    const key = newArr[0];
    const val = newArr[1];
    obj[key] = val;
  });

  return obj[paramKey];
};
//  自动播放音频文件  (解决浏览器禁止默认播放音效)
window.AudioContext =
  window.AudioContext || window.webkitAudioContext || window.mozAudioContext || window.msAudioContext;
const context = new window.AudioContext();
const autoPlayVoice = (url) => {
  let source = null;
  let audioBuffer = null;
  const playSound = () => {
    context.resume();
    source = context.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(context.destination);
    source.start(0);
  };
  const initSound = (arrayBuffer) => {
    context.decodeAudioData(
      arrayBuffer,
      function (buffer) {
        // 解码成功时的回调函数
        audioBuffer = buffer;
        playSound();
      },
      function (e) {
        // 解码出错时的回调函数
        console.log('Error decoding file', e);
      },
    );
  };
  function loadAudioFile(url1) {
    const xhr = new XMLHttpRequest(); //  通过XHR下载音频文件
    xhr.open('GET', url1, true);
    xhr.responseType = 'arraybuffer';
    xhr.onload = function (e) {
      //  下载完成
      initSound(this.response);
    };
    xhr.send();
  }

  //  立即停止
  // const stopSound = () => {
  //   if (source) {
  //     source.stop(0);
  //   }
  // }

  try {
    loadAudioFile(url);
  } catch (error) {
    console.log('!Your browser does not support AudioContext');
  }
};

//  简单window数据缓存 初始化
const initWinData = (str) => {
  if (window[str]) return;

  window[str] = {};
};

//  异步执行函数 测试数据
const asyncTestData = (delay, data) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(data);
    }, delay);
  });
};

//  生成UID
let index = 0;
const getUid = () => {
  const now = +new Date();

  return `rc-upload-${now}-${++index}`;
};

//  退出浏览器全屏

// 判断环境是否为英文
const isEn = () => {
  return window.lang.lang === 'en';
};

function updateURL(gettingQuery, history = this.props.history) {
  let queryString = '?';
  for (const field of Object.keys(gettingQuery)) {
    if (gettingQuery[field] !== null) {
      queryString += `${field}=${gettingQuery[field]}&`;
    }
  }

  const oldQueryString = history?.location.search;
  if (oldQueryString !== queryString) {
    history?.push({
      search: queryString.slice(0, -1),
    });

    // force update if any changes
    // this.forceUpdate();
  }
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// 获取图片高度
function getHeight(imgUrl) {
  return new Promise((resolve) => {
    // 创建对象
    const img = new Image();
    // 改变图片的src
    img.src = imgUrl;

    // 加载完成执行
    img.onload = function () {
      const w = 492;
      // 计算图片高度
      resolve((img.height * w) / img.width);
    };
    img.onerror = () => {
      resolve(0);
    };
  });
}

// 获取图片宽度
function getWidth(imgUrl) {
  return new Promise((resolve) => {
    // 创建对象
    const img = new Image();
    // 改变图片的src
    img.src = imgUrl;

    // 加载完成执行
    img.onload = function () {
      const h = 277;

      // 计算图片高度
      resolve((img.width * h) / img.height);
    };
    img.onerror = () => {
      resolve(0);
    };
  });
}

/**
 * 判断当前窗口是否有弹窗
 * @param {*} tagName
 * @param {*} attribute
 * @param {*} value
 * @returns boolean
 */
function getElementsByAttribute(tagName, attribute, value) {
  const hasEle = false;
  const allElements = document.getElementsByTagName(tagName);
  return Array.from(allElements).some((item) => item.getAttribute(attribute) === value);
}

export {
  deepClone,
  stopChinese,
  wktToJs,
  fullScreen,
  cancelFullScreen,
  tableListIsCheckPrePage,
  addHandle,
  removeHandle,
  getBowserPrefix,
  isDev,
  getLanguageVal,
  langShowStr,
  jsToWkt,
  getLangShowName,
  downloadFile,
  initDrawDataTemp,
  transformConfigdataWKTToJs,
  getUrlParam,
  autoPlayVoice,
  initWinData,
  asyncTestData,
  getUid,
  isEn,
  updateURL,
  sleep,
  getHeight,
  getWidth,
  getElementsByAttribute,
};

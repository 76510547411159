import React, { useState } from 'react'
import { useDispatch, } from 'react-redux'
import { useHistory } from 'react-router';
import { setUser } from '@/store/user';
import { initSkinLayout } from '@/store/comment';
import ajax from '@/services'
import { Input, Message } from '@yisa/webui'
import { setID, aesUtil } from '@/utils'
import '@/assets/css/index.scss'
import { UserOutlined, LockOutlined } from '@yisa/webui/es/Icon';
import './index.scss'

const Login = (pros) => {
  const [formData, setFormData] = useState({
    user: "",
    possword: ""
  })
  const dispatch = useDispatch();
  const history = useHistory()

  const login = () => {
    let password = aesUtil.encrypt(formData.possword)
    ajax.login({ user_name: formData.user, password: password }).then(res => {
      // 本地运行注释打开↓
      // document.cookie = 'YISATOKEN=dasdsadsadsad'
      if (res.status === 20000) {
        let _data = res.data
        dispatch(setUser(_data))
        dispatch(initSkinLayout(_data))
        if (_data && _data.user_info) {
          setID(_data.user_info.id)
          window.user_id = _data.user_info.id
        }
        history.push('/analysis')
      } else {
        Message.error(res.message)
      }
    }).catch(err => {
      console.log(err)
    })
  }


  return (
    <div className="main">
      <div className="login-box">
        <div className="input">
          <UserOutlined />
          <Input
            placeholder="用户名"
            value={formData.user}
            onChange={(v) => {
              setFormData(Object.assign({}, formData, { user: v.target.value }))
            }}
          />
        </div>
        <div className="input">
          <LockOutlined />
          <Input
            placeholder="密码"
            type="password"
            value={formData.possword}
            onChange={(v) => {
              setFormData(Object.assign({}, formData, { possword: v.target.value }))
            }}
          />
        </div>
        <div className="title">忘记密码请联系管理员</div>
        <div className="login-btn" type="primary" onClick={login}>登录</div>

      </div>

    </div>
  )
}

export default Login

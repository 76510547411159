import React, { useState, useEffect, useRef } from 'react';
import { Table, Image, Button, Pagination, Message, Modal, Checkbox, Radio, Loading } from '@yisa/webui';
import { ResultFlow, BigImgSwitch, FormTreeSelect, FormSelect, FormInput, ExportModal, ImageROI } from '@/components';
import { useBoolean } from "ahooks";
import { character } from "@/utils";
import ajax from '@/services';
import errorPng from "@/assets/images/error.png"
import ROIModal from "./ROIModal"
import './index.scss'

// 设备管理
function Equipment(props) {
  // 原始表单
  const defaultFormData = {
    // 所属单位
    business_name: [],
    // 设备名称
    device_name: [],
    // 设备编号
    device_id: "",
    // 设备状态
    device_state: "",
    // 设备位置
    device_address: "",
    // 页码
    page: 1,
    // 数据量
    page_size: character.pageSizeOptions[0],
  }

  const [formData, setFormData] = useState(defaultFormData)
  const [lastFormData, setLastFormData] = useState(formData)
  const [loading, { setTrue, setFalse }] = useBoolean(false)
  const [tableScrollY, setTableScrollY] = useState(200);
  // 编辑弹窗
  const [editVisible, setEditVisible] = useState({
    visible: false,
    visible1: false,
    data: {}
  })
  // 经营单位列表
  const [businessList, setBusinessList] = useState([])
  // 抓拍设备列表
  const [devisceList, setDevisceList] = useState([])
  const [locList, setLocList] = useState([])
  // 表单修改
  const changeValue = (v, k) => {
    setFormData(Object.assign({}, formData, { [k]: v }))
  }
  const [resultData, setResultData] = useState({
    list: [],
    total: 0
  })

  const [imgLoading, setImgLoading] = useState(-1)
  const [bigImgData, setBigImgData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [bigImgVisible, setBigImgVisible] = useState(false);

  // 导出
  const [exportVisible, setExportVisible] = useState(false)
  const columns = [
    {
      title: "设备名称",
      dataIndex: "device_name",
      align: "center",
    },
    {
      title: "所属单位",
      dataIndex: "business_name",
      align: "center",
    },

    {
      title: "经营单位类别",
      dataIndex: "business_type",
      align: "center",
    },
    {
      title: "设备编号",
      dataIndex: "device_id",
      align: "center",
    },
    {
      title: "设备状态",
      dataIndex: "device_state",
      align: "center",
      render: (text) => {
        return <span className={text === 1 ? "state1" : "state2"}>{text === 1 ? "在线" : "离线"}</span>
      }
    },
    {
      title: "设备位置",
      dataIndex: "loc_name",
      align: "center",
    },
    {
      title: "抓图",
      dataIndex: "loc_img",
      align: "center",
      render: (text) => {
        let src = text ? text : errorPng
        return <div className='warn_img'>
          <Image src={src} errorSrc={errorPng}
            onClick={() => {
              if (text) {
                setBigImgVisible(true);
                setCurrentIndex(0);
                setBigImgData([src])
              }
            }} />
        </div>
      }
    },
    {
      title: "操作",
      dataIndex: "capture_correct_num",
      align: "center",
      width: 160,
      render: (text, record, index) => {
        let loc_id = locList.filter(v => v.label == record.loc_name).length ? locList.filter(v => v.label == record.loc_name)[0].value : ""
        return <div className='caozuo'>
          <span className="edit-btn" onClick={() => {
            if (record.loc_img) {
              setEditVisible({
                visible1: true,
                data: { ...record, loc_id: loc_id }
              })
            } else {
              Message.warning("请先抓图")
            }
          }}>编辑</span>
          <span className='img-load'>
            {
              imgLoading == record.device_id ?
                <Loading spinning={true} size="small" />
                : null
            }
            <span className={imgLoading == record.device_id ? "no-edit" : "edit-btn"} onClick={() => {
              if (imgLoading !== record.device_id) {
                getLocImg(record, record.device_id, index)
              }
            }}>抓图</span>
          </span>

          <span className="edit-btn" onClick={() => {
            setEditVisible({
              visible: true,
              data: { ...record, loc_id: loc_id }
            })
          }}>位置</span>
        </div>
      }
    }
  ]

  // 获取抓图
  const getLocImg = (data, id, index) => {
    setImgLoading(id)
    ajax.getLocImg({
      code: data.device_id
    }).then(res => {
      if (res.status === 20000) {
        let arr = [...resultData.list]
        let obj = { ...data, loc_img: res.data.img }
        arr.splice(index, 1, obj)
        setResultData({
          list: arr,
          total: resultData.total
        })
        Message.success(res.message)
      } else {
        Message.error(res.message)
      }
      setImgLoading(-1)
    }).catch(err => console.log(err))
  }
  // 获取列表数据
  const getList = (params = formData) => {
    setTrue(true)
    setLastFormData(params)
    ajax.getDeviceList(params).then(res => {
      if (res.status === 20000) {
        setResultData(res.data)
      } else {
        Message.error(res.message)
      }
      setFalse(false)
    }).catch(err => console.log(err))
  }

  // 获取接收部门
  const getBusinessList = () => {
    ajax
      .getBusiness()
      .then((res) => {
        const { status, message, data } = res;
        if (status === 20000) {
          let arr = [{ label: "全部", value: "-1", children: data }]
          setBusinessList(arr);
        } else {
          Message.error(message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // 获取抓拍设备
  const getDeviceList = () => {
    ajax
      .getDeviceCapture()
      .then((res) => {
        const { status, message, data } = res;
        if (status === 20000) {
          let arr = [{ label: "全部", value: "-1", children: data }]
          setDevisceList(arr);
        } else {
          Message.error(message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // 获取抓拍设备
  const getLocList = () => {
    ajax
      .getLocList()
      .then((res) => {
        const { status, message, data } = res;
        if (status === 20000) {
          let arr = data.map(item => {
            return {
              value: item.locId,
              label: item.locName
            }
          })
          setLocList(arr);
        } else {
          Message.error(message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // 获取抓拍设备
  const updateLoc = () => {
    if (editVisible.data.loc_id === "") return Message.warning("请选择设备位置！")
    ajax
      .updateLoc({
        device_number: editVisible.data.device_id,
        loc_id: editVisible.data.loc_id
      })
      .then((res) => {
        const { status, message, data } = res;
        if (status === 20000) {
          Message.success(message);
          setEditVisible({
            visible: false,
            data: {}
          })
          getList(lastFormData)
        } else {
          Message.error(message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getBusinessList()
    getDeviceList()
    getList(formData)
    getLocList()
  }, [])
  const reset = () => {
    setFormData(defaultFormData)
    getList(defaultFormData)
  }
  useEffect(() => {
    const handleCalcHeight = () => {
      try {
        const resultHeight = document.querySelector(".table-box")
          ? document.querySelector(".table-box").clientHeight - 90
          : 0;
        const thHeight = document.querySelector(".ysd-table-header")
          ? document.querySelector(".ysd-table-header").clientHeight
          : 0;
        const toolHeight = 32 + 20;
        const height = resultHeight - thHeight - toolHeight;
        setTableScrollY(Math.max(height, 200));
      } catch (err) {
        console.log(err);
      }
    };
    handleCalcHeight();
    window.addEventListener("resize", handleCalcHeight);

    return () => {
      window.removeEventListener("resize", handleCalcHeight);
    };
  }, [resultData.total]);
  return <div className="equipment-page">
    <div className="search">
      <div className="title">查询条件</div>
      <FormTreeSelect
        labelPosition="top"
        yisaLabel="设备名称"
        placeholder="请选择设备名称"
        className="search-item"
        value={formData.device_name}
        onChange={(v) => changeValue(v, "device_name")}
        fieldNames={{ title: "label", key: "value" }}
        treeData={devisceList}
      />
      <FormTreeSelect
        labelPosition="top"
        yisaLabel="所属单位"
        placeholder="请选择所属单位"
        className="search-item"
        value={formData.business_name}
        onChange={(v) => changeValue(v, "business_name")}
        fieldNames={{ title: "label", key: "value" }}
        treeData={businessList}
      />
      <FormInput
        className="search-item"
        yisaLabel="设备编号"
        placeholder="请输入设备编号"
        value={formData.device_id}
        onChange={(v) => changeValue(v.target.value, "device_id")}
      />
      <FormSelect
        labelPosition="top"
        yisaLabel="设备状态"
        showClose
        options={[{
          label: "在线",
          value: "1",
        }, {
          label: "离线",
          value: "2",
        }]}
        showUnlimit={true}
        className="search-item"
        placeholder="请选择设备状态"
        value={formData.device_state}
        onChange={(v) => changeValue(v, "device_state")}
      />
      <FormInput
        yisaLabel="设备位置"
        placeholder="请输入设备位置"
        value={formData.device_address}
        onChange={(v) => changeValue(v.target.value, "device_address")}
      />

      <div className="search-btn-box">
        <Button className="reset-btn" onClick={reset}>
          重置
        </Button>
        <Button type="primary" className='search-btn' onClick={() => getList(formData)}>
          搜索
        </Button>

      </div>
    </div>
    <div className="table-box">
      <nav className="total">
        <p>
          共<span> {resultData.total || 0} </span>条结果
        </p>
        <Button className="export-btn" type="primary" onClick={() => setExportVisible(true)}>
          导出
        </Button>
      </nav>

      <ResultFlow
        loading={loading}
        resultData={resultData.list}
      >
        <div className="list">
          <Table
            className="table-warp"
            columns={columns}
            data={resultData.list}
            rowKey="id"
            bordered
            scroll={{
              y: tableScrollY
            }}
            loading={loading}
            pagination={false}
          />
        </div>

        <Pagination
          className="pagination-common"
          showQuickJumper
          showSizeChanger
          size='default'
          position={['bottomCenter']}
          current={lastFormData.page}
          total={resultData.total}
          pageSize={lastFormData.page_size}
          onChange={(page, pageSize) => {
            let obj = {};
            if (pageSize !== lastFormData.page_size) {
              obj.page = 1
              obj.page_size = pageSize
            } else {
              obj.page = page
            }
            let newParams = Object.assign({}, lastFormData, obj);
            getList(newParams)
          }}
          pageSizeOptions={character.pageSizeOptions}
        />

      </ResultFlow>
    </div>
    <Modal
      title='位置'
      className='edit-modal'
      visible={editVisible.visible}
      width={520}
      maskClosable={false}
      okText="提交"
      cancelText="取消"
      onOk={() => {
        updateLoc()
      }}
      onCancel={() => {
        setEditVisible({
          visible: false,
          data: {}
        })
      }}
    >
      <div className="save-content">
        {/* <div className='info-list'>
          <p><span>设备名称：</span><span>{editVisible.data.device_name || '-'}</span></p>
          <p><span>所属单位：</span><span>{editVisible.data.business_name || '-'}</span></p>
          <p><span>经营单位类别：</span><span>{editVisible.data.business_type || '-'}</span></p>
          <p><span>设备状态：</span><span>{editVisible.data.device_state === 1 ? "在线" : "离线" || '-'}</span></p>
        </div> */}
        <div className="modal-form" >
          <div className="cc-form-name">
            <label className="required">*</label>
            <label>设备位置</label>
          </div>
          <div className="bottom">
            {/* <FormSelect
              labelPosition="top"
              yisaLabel=""
              showClose
              options={locList}
              showUnlimit={false}
              className="search-item"
              placeholder="请选择设备位置"
              value={editVisible.data.loc_id}
              onChange={(v) => setEditVisible({
                visible: true,
                data: {
                  ...editVisible.data,
                  loc_id: v
                }
              })}
            /> */}
            <Radio.Group
              className='radio-box'
              options={locList}
              value={editVisible.data.loc_id}
              onChange={(v) => setEditVisible({
                visible: true,
                data: {
                  ...editVisible.data,
                  loc_id: v.target.value
                }
              })}
            />

            {/* <CheckboxGroup
              options={locList}
              value={editVisible.data.loc_id}
              // checked={editVisible.data.loc_id}
              onChange={(v) => setEditVisible({
                visible: true,
                data: {
                  ...editVisible.data,
                  loc_id: v
                }
              })} /> */}
          </div>

        </div>
      </div>
    </Modal>
    <ROIModal
      visible={editVisible.visible1}
      data={editVisible.data}
      onCancel={() => {
        setEditVisible({
          visible1: false,
          data: {}
        })
      }}
    />
    <ExportModal
      total={resultData?.total}
      formData={{ ...lastFormData, page: "", page_size: "" }}
      visible={exportVisible}
      onCancel={() => { setExportVisible(false) }}
      url='/device/export'
      ajaxType="post"
    />
    <BigImgSwitch
      visible={bigImgVisible}
      currentIndex={currentIndex}
      onCancel={() => setBigImgVisible(false)}
      yisaData={bigImgData}
      modalWidth={1400}
      changeNext={(v) => {
        setCurrentIndex(v);
      }}
      changePrev={(v) => {
        setCurrentIndex(v);
      }}
    />
  </div>
}

export default Equipment

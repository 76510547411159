import React, { useState, useEffect } from 'react';
import { Table, Button, Pagination, Message, Modal, TimePicker } from '@yisa/webui';
import { ResultFlow, FormInput, FormSelect } from '@/components';
import { useBoolean } from "ahooks";
import { character } from "@/utils";
import ajax from '@/services';
import dayjs from 'dayjs'
import './index.scss'

function Configuration(props) {

  // 原始表单
  const defaultFormData = {
    // 算法名称
    task_name: "",
    // 页码
    page: 1,
    // 数据量
    page_size: character.pageSizeOptions[0],
  }

  // 编辑弹窗
  const [editVisible, setEditVisible] = useState({
    visible: false,
    data: {}
  })

  const [formData, setFormData] = useState(defaultFormData)
  const [lastFormData, setLastFormData] = useState(formData)
  const [loading, { setTrue, setFalse }] = useBoolean(false)
  const [tableScrollY, setTableScrollY] = useState(200);

  // 表单修改
  const changeValue = (v, k) => {
    setFormData(Object.assign({}, formData, { [k]: v }))
  }
  const [resultData, setResultData] = useState({
    list: [],
    total: 0
  })
  const columns = [
    {
      title: "算法名称",
      dataIndex: "task_name",
      align: "center",
    },
    {
      title: "算法类型",
      dataIndex: "type",
      align: "center",
      render: (text, record) => {
        return <span>{text === 1 ? "农贸市场算法" : "液化气算法"}</span>
      }
    },
    {
      title: "启用时间",
      dataIndex: "time",
      align: "center",
      render: (text, record) => {
        return <span>{record.start_time} ~ {record.end_time}</span>
      }
    },
    {
      title: "操作",
      dataIndex: "capture_correct_num",
      align: "center",
      render: (text, record) => {
        return <span className="edit-btn" onClick={() => {
          setEditVisible({
            visible: true,
            data: record
          })
        }}>编辑</span>
      }
    }

  ]
  // 获取列表数据
  const getList = (params = formData) => {
    setTrue(true)
    setLastFormData(params)
    ajax.getTaskList(params).then(res => {
      if (res.status === 20000) {
        setResultData(res.data)
      } else {
        Message.error(res.message)
      }
      setFalse(false)
    }).catch(err => console.log(err))
  }

  const changeTime = () => {
    ajax.editTaskRange(editVisible.data).then(res => {
      if (res.status === 20000) {
        setEditVisible({
          visible: false,
          data: {}
        })
        getList(lastFormData)
      } else {
        Message.error(res.message)
      }
      setFalse(false)
    }).catch(err => console.log(err))
  }

  useEffect(() => {
    getList(formData)
  }, [])

  const reset = () => {
    setFormData(defaultFormData)
    getList(defaultFormData)
  }
  useEffect(() => {
    const handleCalcHeight = () => {
      try {
        const resultHeight = document.querySelector(".table-box")
          ? document.querySelector(".table-box").clientHeight - 89
          : 0;
        const thHeight = document.querySelector(".ysd-table-header")
          ? document.querySelector(".ysd-table-header").clientHeight
          : 0;
        const toolHeight = 32 + 20;
        const height = resultHeight - thHeight - toolHeight;
        setTableScrollY(Math.max(height, 200));
      } catch (err) {
        console.log(err);
      }
    };
    handleCalcHeight();
    window.addEventListener("resize", handleCalcHeight);

    return () => {
      window.removeEventListener("resize", handleCalcHeight);
    };
  }, [resultData.total]);
  return <div className="configuration-page">
    <div className="search">
      <div className="title">查询条件</div>
      <FormInput
        className="search-item"
        yisaLabel="算法名称"
        placeholder="请输入算法名称"
        value={formData.task_name}
        onChange={(v) => changeValue(v.target.value, "task_name")}
      />
      <div className="search-btn-box">
        <Button className="reset-btn" onClick={reset}>
          重置
        </Button>
        <Button type="primary" className='search-btn' onClick={() => getList(formData)}>
          搜索
        </Button>

      </div>
    </div>
    <div className="table-box">
      <nav className="total">
        <p>
          共<span> {resultData.total || 0} </span>条结果
        </p>
        {/* <ExportBtn
                pn={value.page}
                pageSize={value.page_size}
                totalRecords={result.total}
                modalType="noImg"
                postData={{
                  ...value,
                  export_type: "1",
                }}
                ajaxType="post"
                exportUrl={`/api/work_count/get_list`}
              /> */}
      </nav>

      <ResultFlow
        loading={loading}
        resultData={resultData.list}
      >
        <Table
          className="table-warp"
          columns={columns}
          data={resultData.list}
          rowKey="id"
          bordered
          scroll={{
            y: tableScrollY
          }}
          loading={loading}
          pagination={false}
        />
        <Pagination
          className="pagination-common"
          showQuickJumper
          showSizeChanger
          size='default'
          position={['bottomCenter']}
          current={lastFormData.page}
          total={resultData.total}
          pageSize={lastFormData.page_size}
          onChange={(page, pageSize) => {
            let obj = {};
            if (pageSize !== lastFormData.page_size) {
              obj.page = 1
              obj.page_size = pageSize
            } else {
              obj.page = page
            }
            let newParams = Object.assign({}, lastFormData, obj);
            getList(newParams)
          }}
          pageSizeOptions={character.pageSizeOptions}
        />
      </ResultFlow>
    </div>
    <Modal
      title='编辑'
      className='edit-modal'
      visible={editVisible.visible}
      width={400}
      maskClosable={false}
      okText="提交"
      cancelText="取消"
      onOk={() => {
        changeTime()
      }}
      onCancel={() => {
        setEditVisible({
          visible: false,
          data: {}
        })
      }}
    >
      <div className="save-content">
        <div className='info-list'>
          <p><span>算法名称：</span><span>{editVisible.data.task_name || '-'}</span></p>
          <p><span>算法类型：</span><span>{editVisible.data.type === 1 ? "农贸市场算法" : "液化气算法" || '-'}</span></p>
        </div>
        <div className="modal-form">
          <div className="cc-form-name">
            <label className="required">*</label>
            <label>启用时间</label>
          </div>
          <div className="bottom">
            <TimePicker onChange={(time, timeString) => {
              setEditVisible({
                visible: true,
                data: {
                  ...editVisible.data,
                  start_time: timeString
                }
              })
            }}
              allowClear={false}
              format="HH:mm:ss"
              value={dayjs(editVisible.data.start_time, "HH:mm:ss")}
            />

            <div className="margin">~</div>
            <TimePicker
              onChange={(v, timeString) => {
                setEditVisible({
                  visible: true,
                  data: {
                    ...editVisible.data,
                    end_time: timeString
                  }
                })
              }}
              allowClear={false}
              value={dayjs(editVisible.data.end_time, "HH:mm:ss")}
            />
          </div>
        </div>

      </div>
    </Modal>
  </div>
}

export default Configuration

import React from 'react';
import './index.scss'



function Home(props) {



  return <div className="home-page">
   首页
  </div>
}

export default Home

import React, { useState, useEffect, useRef } from 'react';
import { Table, Button, Pagination, Message } from '@yisa/webui';
import { ResultFlow, FormTreeSelect, FormSelect, FormInput, ExportModal } from '@/components';
import { useBoolean } from "ahooks";
import { character } from "@/utils";
import ajax from '@/services';
import './index.scss'

function CompanyArchives(props) {
  // 原始表单
  const defaultFormData = {
    // 经营单位名称
    business_name: [],
    // 经营单位类别
    business_type: "-1",
    // 经营单位联系人
    business_person: "",
    // 经营单位联系方式
    business_tel: "",
    // 社会信用代码
    social_credit_code: "",
    // 许可证号
    license_number: "",
    // 监管单位
    supervisory_unit: "",
    // 监管单位负责人
    supervisory_person: "",
    // 监管单位联系方式
    supervisory_tel: "",
    // 页码
    page: 1,
    // 数据量
    page_size: character.pageSizeOptions[0],
  }

  const [formData, setFormData] = useState(defaultFormData)
  const [lastFormData, setLastFormData] = useState(formData)
  const [loading, { setTrue, setFalse }] = useBoolean(false)
  const [tableScrollY, setTableScrollY] = useState(200);

  // 经营单位列表
  const [businessList, setBusinessList] = useState([])
  // 表单修改
  const changeValue = (v, k) => {
    setFormData(Object.assign({}, formData, { [k]: v }))
  }
  // 导出
  const [exportVisible, setExportVisible] = useState(false)
  const [resultData, setResultData] = useState({
    list: [],
    total: 0
  })
  const columns = [

    {
      title: "经营单位名称",
      dataIndex: "business_name",
      align: "center",
    },

    {
      title: "经营单位类别",
      dataIndex: "business_type",
      align: "center",
    },
    {
      title: "经营单位联系人",
      dataIndex: "business_person",
      align: "center",
    },
    {
      title: "经营单位联系方式",
      dataIndex: "business_tel",
      align: "center",
    },
    {
      title: "社会信用代码",
      dataIndex: "social_credit_code",
      align: "center",
    },
    {
      title: "许可证号",
      dataIndex: "license_number",
      align: "center",
    },

    {
      title: "监管单位",
      dataIndex: "supervisory_unit",
      align: "center",
    },
    {
      title: "监管单位负责人",
      dataIndex: "supervisory_person",
      align: "center",
    },
    {
      title: "监管单位联系方式",
      dataIndex: "supervisory_tel",
      align: "center",
    },
    {
      title: "网络带宽",
      dataIndex: "network_bandwidth",
      align: "center",
    },
    {
      title: "设备数量",
      dataIndex: "device_num",
      align: "center",
    }
  ]
  // 获取列表数据
  const getList = (params = formData) => {
    setTrue(true)
    setLastFormData(params)
    ajax.getBusinessList(params).then(res => {
      if (res.status === 20000) {
        setResultData(res.data)
      } else {
        Message.error(res.message)
      }
      setFalse(false)
    }).catch(err => console.log(err))
  }

  // 获取接收部门
  const getBusinessList = () => {
    ajax
      .getBusiness()
      .then((res) => {
        const { status, message, data } = res;
        if (status === 20000) {
          let arr = [{ label: "全部", value: "-1", children: data }]
          setBusinessList(arr);
        } else {
          Message.error(message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getBusinessList()
    getList(formData)
  }, [])

  const reset = () => {
    setFormData(defaultFormData)
    getList(defaultFormData)
  }
  useEffect(() => {
    const handleCalcHeight = () => {
      try {
        const resultHeight = document.querySelector(".table-box")
          ? document.querySelector(".table-box").clientHeight - 89
          : 0;
        const thHeight = document.querySelector(".ysd-table-header")
          ? document.querySelector(".ysd-table-header").clientHeight
          : 0;
        const toolHeight = 32 + 20;
        const height = resultHeight - thHeight - toolHeight;
        setTableScrollY(Math.max(height, 200));
      } catch (err) {
        console.log(err);
      }
    };
    handleCalcHeight();
    window.addEventListener("resize", handleCalcHeight);

    return () => {
      window.removeEventListener("resize", handleCalcHeight);
    };
  }, [resultData.total]);
  return <div className="company-archives-page">
    <div className="search">
      <div className="title">查询条件</div>
      <FormTreeSelect
        labelPosition="top"
        yisaLabel="经营单位名称"
        placeholder="请选择经营单位名称"
        className="search-item"
        value={formData.business_name}
        onChange={(v) => changeValue(v, "business_name")}
        fieldNames={{ title: "label", key: "value" }}
        treeData={businessList}
      />
      <FormSelect
        labelPosition="top"
        yisaLabel="经营单位类别"
        allowClear={false}
        options={[{
          label: "农贸市场",
          value: "1",
        }, {
          label: "液化气单位",
          value: "2",
        }]}
        showUnlimit={true}
        className="search-item"
        placeholder="请选择经营单位类别"
        value={formData.business_type}
        onChange={(v) => changeValue(v, "business_type")}
      />

      <FormInput
        className="search-item"
        yisaLabel="经营单位联系人"
        placeholder="请输入经营单位联系人"
        value={formData.business_person}
        onChange={(v) => changeValue(v.target.value, "business_person")}
      />

      <FormInput
        className="search-item"
        yisaLabel="经营单位联系方式"
        placeholder="请输入经营单位联系方式"
        value={formData.business_tel}
        onChange={(v) => changeValue(v.target.value, "business_tel")}
      />
      <FormInput
        className="search-item"
        yisaLabel="社会信用代码"
        placeholder="请输入社会信用代码"
        value={formData.social_credit_code}
        onChange={(v) => changeValue(v.target.value, "social_credit_code")}
      />
      <FormInput
        className="search-item"
        yisaLabel="许可证号"
        placeholder="请输入许可证号"
        value={formData.license_number}
        onChange={(v) => changeValue(v.target.value, "license_number")}
      />
      <FormInput
        className="search-item"
        yisaLabel="监管单位"
        placeholder="请输入监管单位"
        value={formData.supervisory_unit}
        onChange={(v) => changeValue(v.target.value, "supervisory_unit")}
      />
      <FormInput
        className="search-item"
        yisaLabel="监管单位负责人"
        placeholder="请输入监管单位负责人"
        value={formData.supervisory_person}
        onChange={(v) => changeValue(v.target.value, "supervisory_person")}
      />
      <FormInput
        className="search-item"
        yisaLabel="监管单位联系方式"
        placeholder="请输入监管单位联系方式"
        value={formData.supervisory_tel}
        onChange={(v) => changeValue(v.target.value, "supervisory_tel")}
      />

      <div className="search-btn-box">
        <Button className="reset-btn" onClick={reset}>
          重置
        </Button>
        <Button type="primary" className='search-btn' onClick={() => getList(formData)}>
          搜索
        </Button>

      </div>
    </div>
    <div className="table-box">
      <nav className="total">
        <p>
          共<span> {resultData.total || 0} </span>条结果
        </p>
        <Button className="export-btn" type="primary" onClick={() => setExportVisible(true)}>
          导出
        </Button>
      </nav>

      <ResultFlow
        loading={loading}
        resultData={resultData.list}
      >
        <div className="list">
          <Table
            className="table-warp"
            columns={columns}
            data={resultData.list}
            rowKey="id"
            bordered
            scroll={{
              y: tableScrollY
            }}
            loading={loading}
            pagination={false}
          />
        </div>
        <Pagination
          className="pagination-common"
          showQuickJumper
          showSizeChanger
          size='default'
          position={['bottomCenter']}
          current={lastFormData.page}
          total={resultData.total}
          pageSize={lastFormData.page_size}
          onChange={(page, pageSize) => {
            let obj = {};
            if (pageSize !== lastFormData.page_size) {
              obj.page = 1
              obj.page_size = pageSize
            } else {
              obj.page = page
            }
            let newParams = Object.assign({}, lastFormData, obj);
            getList(newParams)
          }}
          pageSizeOptions={character.pageSizeOptions}
        />

      </ResultFlow>
    </div>
    <ExportModal
      total={resultData?.total}
      formData={{ ...lastFormData, page: "", page_size: "" }}
      visible={exportVisible}
      onCancel={() => { setExportVisible(false) }}
      url='/business/export'
      ajaxType="post"
    />
  </div>
}

export default CompanyArchives

import Layout from './Layout'
import _404 from './_404'
import _403 from './_403'
import Login from './Login'
import Home from './Home'
import ErrorTest from './ErrorTest'
import Analysis from './Analysis'
import CylinderStatistics from './CylinderStatistics'
import Equipment from './Equipment'
import Configuration from "./Configuration"
import WarnJudge from "./WarnJudge"
import PersonArchives from './PersonArchives'
import CompanyArchives from './CompanyArchives'
import Manage from './Manage'

export default {
  Layout,
  _404,
  _403,
  Login,
  Home,
  ErrorTest,
  Analysis,
  Equipment,
  Configuration,
  WarnJudge,
  PersonArchives,
  CompanyArchives,
  Manage,
  CylinderStatistics
}

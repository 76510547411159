import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { removeToken, setID } from '@/utils'
import { Message, Popover, Space } from "@yisa/webui";
import { Icon, LockOutlined, MailOutlined } from '@yisa/webui/es/Icon';
import { ModalEditPwd } from '@/components';
import ajax from '@/services'
import './index.scss'

export default (pros) => {
  const history = useHistory();

  const userInfo = useSelector((state) => {
    return state.user.userInfo
  });
  const [showPosw, setShowPosw] = useState(false)
  let timer = null;

  const [badge, setBadge] = useState({
    id: "",
    total: 0
  })

  const logout = () => {
    // 退出登录
    removeToken()
    window.user_id = undefined
    setID(undefined)
    window.location.replace(sysConfig.login_url)
    ajax.logout().then(res => {
      if (res.status === 20000) {
        Message.success("退出成功")
      } else {
        Message.error(res.message)
      }
    }).catch(err => {
      console.log(err)
    })
  }

  const contentUser = (
    <div className='info'>
      <div className='username'>
        <span>用户ID：</span>
        <span className='name'>{userInfo.name}</span>
      </div>
      <div className='role'>
        <span className='label' >角色：</span>
        <Space className='role-list' size={6} wrap='wrap'>
          <span className='role-name'>{userInfo.role}</span>
        </Space>
      </div>
    </div>
  );
  const getData = () => {
    ajax.getWarningMsg().then(res => {
      if (res.status === 20000) {
        setBadge(res.data)
      } else {
        Message.error(res.message)
      }
    }).catch(err => {
      console.log(err)
    })
  }

  const chickBadge = () => {
    ajax.warningRead({ id: badge.id }).then(res => {
      if (res.status === 20000) {
        setBadge({
          id: "",
          total: 0
        })
        history.push('/warnJudge')
      } else {
        Message.error(res.message)
      }
    }).catch(err => {
      console.log(err)
    })
  }
  useEffect(() => {
    // timer = setInterval(() => {
    getData()
    // }, 10000)
    // return () => {
    //   clearInterval(timer)
    // }
  }, [])

  return <div className="right-tools">

    <span title='修改密码' onClick={() => {
      setShowPosw(true)
    }} ><Icon type='xiugaimima' />
    </span>
    <span title='消息通知' className='msg' onClick={() => {
      chickBadge()
    }}>
      <Icon type='xiaoxi1' />
      {
        badge.total > 0 ? <div className="badge">{badge.total > 99 ? "99+" : badge.total}</div> : ""
      }
    </span>
    <Popover overlayClassName='user-info' content={contentUser} placement="bottom">
      <span><Icon type='yonghu' /></span>
    </Popover>
    <span onClick={logout} title='退出'>
      <Icon type='tuichu' />
    </span>
    <ModalEditPwd
      visible={showPosw}
      onCancel={() => {
        setShowPosw(false)
      }}
    />
  </div>
}

import { createSlice } from '@reduxjs/toolkit';

const createRandom = () => {
  return new Date().getTime() + '_' + Math.random()
}

const changeTheme = (skin) => {
  document.body.className = 'theme-' + skin
  localStorage.setItem(systemSkinKey, skin)
  switch (skin) {
    case 'light':
      document.getElementById('theme-dark').disabled = true
      document.getElementById('theme-blue').disabled = true
      break
    case 'dark':
      document.getElementById('theme-dark').disabled = false
      document.getElementById('theme-blue').disabled = true
      break
    case 'blue':
      document.getElementById('theme-dark').disabled = true
      document.getElementById('theme-blue').disabled = false
      break
  }
}

export const commonSlice = createSlice({
  name: 'comment',
  initialState: {
    skin: 'light',
    sidebarWidth: '',
    layout: 'vertical',
    secretKey:"",
    routerData: {
      name: '',
      breadcrumb: []
    },
  },
  reducers: {
    setSkin: (state, action) => {
      let skin = action.payload
      changeTheme(skin)
      state.skin = skin
    },
    setLayout: (state, action) => {
      let layout = action.payload
      state.layout = layout
    },
    setSecretKEY: (state, action) => {
      let secretKey = action.payload
      state.secretKey = secretKey
    },
    initSkinLayout: (state, action) => {
      let skin = action.payload.color || sysConfig.d_color
      changeTheme(skin)
      state.skin = skin

      state.layout = action.payload.layout || sysConfig.d_layout
    },
    setSidebarWidth: (state, action) => {
      state.sidebarWidth = createRandom();
    },
    setRouterData: (state, action) => {
      state.routerData = {
        breadcrumb: action.payload.breadcrumb || [],
        name: action.payload.name || ''
      }
    },
  }
});

export const {
  setSkin,
  setLayout,
  setSecretKEY,
  initSkinLayout,
  setSidebarWidth,
  setRouterData
} = commonSlice.actions;


export default commonSlice.reducer;

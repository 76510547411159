import React, { useEffect, useState } from "react";
import { Modal, Tabs, Button, Table, Message, Image } from "@yisa/webui"
import { HorizontalMoreOutlined, LeftTurnOutlined } from '@yisa/webui/es/Icon';
import { useSelector } from "react-redux";
import { BigImgSwitch } from '@/components';
import errorPng from "@/assets/images/error.png"
import ajax from '@/services';

function DetailModal(props) {

  const { visible, data, onCancel, onClose } = props
  const userInfo = useSelector((state) => {
    return state.user.userInfo
  })
  const [activeKey, setActiveKey] = useState("1")
  const [bigImgData, setBigImgData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [bigImgVisible, setBigImgVisible] = useState(false);

  const [resData, setResData] = useState({})
  // 0异常预警 1待确认 2经营单位审核 3监管单位审核 3完结
  const columns = [
    {
      title: "任务节点",
      dataIndex: "task_node",
      align: "center",
    },
    {
      title: "操作",
      dataIndex: "operate",
      align: "center",
    },
    {
      title: "操作人",
      dataIndex: "operate_person",
      align: "center",
      width: 90,
    },

    {
      title: "所属单位",
      dataIndex: "business_name",
      align: "center",
    },
    {
      title: "联系方式",
      dataIndex: "tel",
      align: "center",
    },
    {
      title: "操作时间",
      dataIndex: "time",
      align: "center",
    }
  ]
  // 获取预警详情
  const getDetail = () => {
    ajax
      .getWarningInfo()
      .then((res) => {
        const { status, message, data } = res;
        if (status === 20000) {
          setResData(data);
        } else {
          Message.error(message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // 审核驳回确认
  const handleChange = (type) => {
    ajax
      .warningProcess({ id: resData.id, violation_id: resData.violation_id, status: type, business_type: resData.business_type })
      .then((res) => {
        const { status, message, data } = res;
        if (status === 20000) {
          // setResData(data);
          setActiveKey("1")
          onCancel()
        } else {
          Message.error(message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (visible) {
      setResData(data)
    }
  }, [visible])

  const returnText = (key) => {
    let text = ""
    switch (key) {
      case 0:
      case 4:
        text = "待反馈";
        break;
      case 1:
      case 5:
        text = "待确认";
        break;
      case 2:
        text = "待审核";
        break;
      case 3:
        text = "已完结";
        break;

    }
    return text
  }
  return (<>
    <Modal
      title='查看'
      className='warn-modal'
      visible={visible}
      width={1120}
      maskClosable={false}
      footer={null}
      onCancel={() => {
        onClose()
        setActiveKey("1")
        setResData({})
      }}
    >
      <div className="save-content">
        <div className="top">
          <div className="name">{resData?.task_number}</div>
          <div className={`tag tag${resData?.rectification_state}`}>{returnText(resData?.rectification_state)}</div>
        </div>
        <div className="tab-box">
          <Tabs activeKey={activeKey} onChange={(key) => { setActiveKey(key) }} data={resData.business_type === "液化气单位" ?
            [
              { key: "1", name: '预警信息' },
              { key: "3", name: '工作进展' }] :
            [
              { key: "1", name: '预警信息' },
              { key: "2", name: '反馈信息' },
              { key: "3", name: '工作进展' }
            ]} />
        </div>
        {
          activeKey === "1" ? <div className="base-info">
            <p><span>预警单位：</span><span>{resData?.business_name || "-"}</span></p>
            <p><span>经营单位联系人：</span><span>{resData?.business_person || "-"}</span></p>
            <p><span>经营单位联系方式：</span><span>{resData?.business_tel || "-"}</span></p>
            <p><span>违规行为：</span><span>{resData?.violation || "-"}</span></p>
            <p><span>预警时间：</span><span>{resData?.warn_time || "-"}</span></p>
            <p><span>预警设备：</span><span>{resData?.warn_device || "-"}</span></p>
            <p><span>监管单位：</span><span>{resData?.supervisory_unit || "-"}</span></p>
            <p><span>监管单位负责人：</span><span>{resData?.supervisory_person || "-"}</span></p>
            <p><span>监管单位联系方式：</span><span>{resData?.supervisory_tel || "-"}</span></p>
          </div> : ''
        }
        {
          activeKey === "1" ? <div className="base-img">
            <p><span>预警图片：</span>
              <div>
                {
                  resData.warn_img && resData?.warn_img.map((item, index) => {
                    return <Image src={item} errorSrc={errorPng} onClick={() => {
                      setBigImgVisible(true);
                      setCurrentIndex(index);
                      setBigImgData(resData.warn_img)
                    }} />
                  })
                }

              </div>
            </p>
            {
              resData.warn_video ? <p><span>视频：</span>
                <div>
                  <video src={resData.warn_video} width="400" height="300" controls="controls" preload="auto"></video>
                </div>
              </p> : null
            }
          </div> : ''
        }

        {
          activeKey === "2" ? <div className="feedback-info">
            <p><span>抓拍结果确认：</span><span>{resData?.is_right === 1 ? "正确" : "错误"}</span></p>
            <p><span>整改结果反馈：</span><span>{resData?.feedback_info || "-"}</span></p>
            <p><span>整改时间：</span><span>{resData?.feedback_time || "-"}</span></p>
          </div> : ''
        }
        {
          activeKey === "2" && resData.feedback_image.length ? <div className="base-img">
            <p><span>整改图片：</span>
              <div>
                {
                  resData.feedback_image && resData?.feedback_image.map((item, index) => {
                    console.log(item)
                    return <Image src={item} errorSrc={errorPng} onClick={() => {
                      setBigImgVisible(true);
                      setCurrentIndex(index);
                      setBigImgData(resData.feedback_image)
                    }} />
                  })
                }
              </div>
            </p>
          </div> : activeKey === "2" ? <div className="base-img1"></div> : null
        }
        {
          activeKey === "3" ? <div className="step-table">
            <div className="step-box">
              {resData?.work_progress && resData.work_progress.length ? resData.work_progress.map((item, index) => {
                return <div className="step" key={index}>
                  <div className={`left-line line${item.type}`}></div>
                  <div className="node">
                    {
                      item.type == 2 ? <div className="step-top">当前任务节点</div> : null
                    }
                    <div className={`step-center step-center${item.type}`}>
                      {
                        item.type === 1 ? <div className="icon1"></div> :
                          item.type === 2 ? <div className="icon2"></div> :
                            item.type === 3 ? <HorizontalMoreOutlined /> :
                              <LeftTurnOutlined />
                      }
                    </div>
                    <div className={`step-bottom text${item.type}`}>{item.name}</div>
                  </div>
                </div>
              })
                : null}

            </div>
            <div className="table-title">操作记录</div>
            <div className="record-table">
              <Table
                className="table-warp"
                columns={columns}
                data={resData.operation_record}
                rowKey="id"
                bordered
                scroll={{
                  y: 132,
                  scrollToFirstRowOnChange: true,
                }}
                // loading={loading}
                pagination={false}
              />
            </div>
          </div> : null
        }

      </div>
      {
        userInfo.user_type === 3 && resData?.rectification_state === 2 ? <div className="modal-footer">
          <Button type="primary" className="reset-btn" onClick={() => { handleChange(1) }}>
            驳回
          </Button>
          <Button type="primary" className='search-btn' onClick={() => { handleChange(3) }}>
            通过
          </Button>

        </div> : null
      }

      {
        userInfo.user_type === 2 && (resData?.rectification_state === 1 || resData.rectification_state === 5) ? <div className="modal-footer">
          <Button type="primary" className='search-btn' onClick={() => { handleChange(3) }}>
            确认
          </Button>
        </div> : null
      }

    </Modal>
    <BigImgSwitch
      visible={bigImgVisible}
      currentIndex={currentIndex}
      onCancel={() => setBigImgVisible(false)}
      yisaData={bigImgData}
      modalWidth={1400}
      changeNext={(v) => {
        setCurrentIndex(v);
      }}
      changePrev={(v) => {
        setCurrentIndex(v);
      }}
    />
  </>
  )
}
export default DetailModal

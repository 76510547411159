import React, { useState, useEffect, useRef } from 'react';
import { Table, Button, Pagination, Message, TreeSelect } from '@yisa/webui';
import { FormDatePicker, ResultFlow, FormTreeSelect, FormSelect, FormInputGroup, ExportModal } from '@/components';
import { useBoolean } from "ahooks";
import { character } from "@/utils";
import dayjs from "dayjs";
import ajax from '@/services';
import './index.scss'

function CylinderStatistics(props) {

  // 原始表单
  const defaultFormData = {
    year: "",
  }
  const [formData, setFormData] = useState(defaultFormData)
  const [lastFormData, setLastFormData] = useState(formData)
  const [loading, { setTrue, setFalse }] = useBoolean(false)
  // 表单修改
  const changeValue = (v, k) => {
    setFormData(Object.assign({}, formData, { [k]: v }))
  }
  const [tableScrollY, setTableScrollY] = useState(200);

  const [resultData, setResultData] = useState({
    list: [],
    total: 0
  })

  const columns = [
    {
      title: '单位',
      dataIndex: 'org_name',
      key: 'org_name',
    },
    {
      title: '1月',
      dataIndex: '1月',
      key: '1月',
    },
    {
      title: '2月',
      dataIndex: '2月',
      key: '2月',
    },
    {
      title: '3月',
      dataIndex: '3月',
      key: '3月',
    },
    {
      title: '4月',
      dataIndex: '4月',
      key: '4月',
    },
    {
      title: '5月',
      dataIndex: '5月',
      key: '5月',
    },
    {
      title: '6月',
      dataIndex: '6月',
      key: '6月',
    },
    {
      title: '7月',
      dataIndex: '7月',
      key: '7月',
    },
    {
      title: '8月',
      dataIndex: '8月',
      key: '8月',
    },
    {
      title: '9月',
      dataIndex: '9月',
      key: '9月',
    },
    {
      title: '10月',
      dataIndex: '10月',
      key: '10月',
    },
    {
      title: '11月',
      dataIndex: '11月',
      key: '11月',
    },
    {
      title: '12月',
      dataIndex: '12月',
      key: '12月',
    },
  ];
  // 获取列表数据
  const getList = (params = formData) => {
    setTrue(true)
    setLastFormData(params)
    ajax.gasStatistics(params).then(res => {
      if (res.status === 20000) {
        const dataSource = res.data.map((item) => {
          const rowData = {
            key: item.org_id,
            org_name: item.org_name,
          };

          item.numbers.forEach((num) => {
            const month = `${num.month}月`;
            rowData[month] = num.number;
          });

          return rowData;
        });
        setResultData({ list: dataSource })
      } else {
        Message.error(res.message)
      }
      setFalse(false)
    }).catch(err => console.log(err))
  }

  useEffect(() => {
    getList(formData)
  }, [])

  const reset = () => {
    setFormData(defaultFormData)
    getList(defaultFormData)
  }
  useEffect(() => {
    const handleCalcHeight = () => {
      try {
        const resultHeight = document.querySelector(".table-box")
          ? document.querySelector(".table-box").clientHeight - 89
          : 0;
        const thHeight = document.querySelector(".ysd-table-header")
          ? document.querySelector(".ysd-table-header").clientHeight
          : 0;
        const toolHeight = 32 + 20;
        const height = resultHeight - thHeight - toolHeight;
        setTableScrollY(Math.max(height, 200));
      } catch (err) {
        console.log(err);
      }
    };
    handleCalcHeight();
    window.addEventListener("resize", handleCalcHeight);

    return () => {
      window.removeEventListener("resize", handleCalcHeight);
    };
  }, [resultData.total]);

  return <div className="cylinder-statistics-pages">
    <div className="search">
      <div className="title">查询条件</div>

      <FormDatePicker
        className="search-item"
        picker="year"
        labelPosition="top"
        format="YYYY"
        showTime={true}
        yisaLabel="年份"
        value={formData.year}
        onChange={(e) => changeValue(e, "year")}
      />
      <div className="search-btn-box">
        <Button className="reset-btn" onClick={reset} >
          重置
        </Button>
        <Button type="primary" className='search-btn' onClick={() => getList(formData)}>
          搜索
        </Button>

      </div>
    </div>
    <div className="table-box">
      <nav className="total">
        <p>
          {/* 共<span> {resultData.total || 0} </span>条结果 */}
        </p>
        {/* <Button className="export-btn" type="primary" onClick={() => setExportVisible(true)}>
          导出
        </Button> */}
      </nav>

      <ResultFlow
        loading={loading}
        resultData={resultData.list}
      >
        <div className="list">
          <Table
            className="table-warp"
            columns={columns}
            data={resultData.list}
            rowKey="id"
            bordered
            scroll={{
              y: tableScrollY,
            }}
            loading={loading}
            pagination={false}
          />
        </div>

        {/* <Pagination
            className="pagination-common"
            showQuickJumper
            showSizeChanger
            size='default'
            position={['bottomCenter']}
            current={lastFormData.page}
            total={resultData.total}
            pageSize={lastFormData.page_size}
            onChange={(page, pageSize) => {
              let obj = {};
              if (pageSize !== lastFormData.page_size) {
                obj.page = 1
                obj.page_size = pageSize
              } else {
                obj.page = page
              }
              let newParams = Object.assign({}, lastFormData, obj);
              getList(newParams)
            }}
            pageSizeOptions={character.pageSizeOptions}
          /> */}
      </ResultFlow>
    </div>
    {/* <ExportModal
      total={resultData?.total}
      formData={{ ...lastFormData, page: "", page_size: "" }}
      visible={exportVisible}
      onCancel={() => { setExportVisible(false) }}
      url='/summary/export'
      ajaxType="post"
    /> */}
  </div>
}

export default CylinderStatistics

import { ajax } from '@/utils';

export default {
  // 分析列表
  getSummaryList: (data) => (
    ajax({
      url: '/summary/list',
      method: "post",
      params: data
    })
  ),
  // 设备列表
  getDeviceList: (data) => (
    ajax({
      url: '/device/list',
      method: "post",
      params: data
    })
  ),
  // 获取抓图
  getLocImg: (data) => (
    ajax({
      url: '/device/capture',
      method: "get",
      params: data
    })
  ),
  // 查询设备所挂载的算法
  getLocTask: (data) => (
    ajax({
      url: '/task/by-loc',
      method: "get",
      params: data
    })
  ),
  // 获取设备的算法配置
  getAlgconf: (data) => (
    ajax({
      url: '/device/alg-conf',
      method: "get",
      params: data
    })
  ),
  // 更新算法配置
  addAlgconf: (data) => (
    ajax({
      url: '/device/alg-conf',
      method: "put",
      params: data
    })
  ),
  // 气瓶个数统计
  gasStatistics: (data) => (
    ajax({
      url: '/summary/gas-statistics',
      method: "get",
      params: data
    })
  ),
  // getAlgconf: (data) => (
  //   ajax({
  //     url: '/device/alg-conf',
  //     method: "post",
  //     params: data
  //   })
  // ),

  // 获取位置列表
  getLocList: (data) => (
    ajax({
      url: '/loc/list',
      method: "get",
      params: data
    })
  ),
  // 更新设备所在位置
  updateLoc: (data) => (
    ajax({
      url: '/loc/update',
      method: "post",
      params: data
    })
  ),
  // 算法列表
  getTaskList: (data) => (
    ajax({
      url: '/task/list',
      method: "get",
      params: data
    })
  ),
  // 编辑算法
  editTaskRange: (data) => (
    ajax({
      url: '/task/range',
      method: "get",
      params: data
    })
  ),
  // 经营单位列表
  getBusinessList: (data) => (
    ajax({
      url: '/business/list',
      method: "post",
      params: data
    })
  ),
  // 获取单位信息
  getUnitList: (data) => (
    ajax({
      url: '/common/get-unit-list',
      method: "get",
      params: data
    })
  ),
  // 获取单位信息
  getRoleList: (data) => (
    ajax({
      url: '/common/get-role',
      method: "get",
      params: data
    })
  ),
  // 用户管理列表
  getUserList: (data) => (
    ajax({
      url: '/user/list',
      method: "post",
      params: data
    })
  ),
  // 删除用户
  getUserDel: (data) => (
    ajax({
      url: '/user/del',
      method: "post",
      params: data
    })
  ),
  resetUser: (data) => (
    ajax({
      url: '/user/reset',
      method: "post",
      params: data
    })
  ),

  // 编辑用户
  userEdit: (data) => (
    ajax({
      url: '/user/edit',
      method: "post",
      params: data
    })
  ),
  // 部门管理列表树
  getOrganizationList: (data) => (
    ajax({
      url: '/organization/list',
      method: "get",
      params: data
    })
  ),
  // 新增部门
  organizationAdd: (data) => (
    ajax({
      url: '/organization/add',
      method: "post",
      params: data
    })
  ),
  // 编辑部门
  organizationEdit: (data) => (
    ajax({
      url: '/organization/edit',
      method: "post",
      params: data
    })
  ),
  // 删除部门
  organizationDel: (data) => (
    ajax({
      url: '/organization/del',
      method: "post",
      params: data
    })
  ),
  // 获取监管单位负责人姓名
  getSuperviseList: (data) => (
    ajax({
      url: '/supervise/list',
      method: "get",
      params: data
    })
  ),
  // 获取经营单位详情
  getBusinessDetail: (data) => (
    ajax({
      url: '/organization/detail',
      method: "get",
      params: data
    })
  ),
  // 人员档案
  getEmployeeList: (data) => (
    ajax({
      url: '/employee/list',
      method: "get",
      params: data
    })
  ),
  employeeAdd: (data) => (
    ajax({
      url: '/employee/add',
      method: "post",
      params: data
    })
  ),
  employeeDel: (data) => (
    ajax({
      url: '/employee/del',
      method: "get",
      params: data
    })
  ),
  // 预警处置列表
  getWarningList: (data) => (
    ajax({
      url: '/warning/list',
      method: "post",
      params: data
    })
  ),
  // 预警处置详情
  getWarningInfo: (data) => (
    ajax({
      url: '/warning/info',
      method: "get",
      params: data
    })
  ),
  // 预警处置
  warningProcess: (data) => (
    ajax({
      url: '/warning/auditing',
      method: "post",
      params: data
    })
  ),
}

/**
 *  @description  路由配置，菜单导航最多支持三级。
 *
 *  一、 path  路径； name 选中菜单的路径；fixedTheme 固定为某个主题
 *
 *  二、 单独布局的页面放到上面，不然会被其他路由拦截
 *
 * */

import pages from "@/pages";

export default [
  {
    path: "/",
    component: pages.Layout,
    children: [
      {
        path: "/analysis",
        name: '/analysis',
        component: pages.Analysis,
        needPermission: true,
        breadcrumb: [
          {
            text: '违规数据'
          },
        ],
      },
      {
        path: "/cylinderStatistics",
        name: '/cylinderStatistics',
        component: pages.CylinderStatistics,
        needPermission: true,
        breadcrumb: [
          {
            text: '气瓶个数统计'
          },
        ],
      },
      {
        path: "/equipment",
        name: '/equipment',
        component: pages.Equipment,
        needPermission: true,
        breadcrumb: [
          {
            text: '设备管理'
          },
        ],
      },
      {
        path: "/configuration",
        name: '/configuration',
        component: pages.Configuration,
        needPermission: true,
        breadcrumb: [
          {
            text: '算法启用时间配置'
          },
        ],
      },
      {
        path: "/warnJudge",
        name: '/warnJudge',
        component: pages.WarnJudge,
        needPermission: true,
        breadcrumb: [
          {
            text: '违规预警处置'
          },
        ],
      },
      {
        path: "/company_archives",
        name: '/company_archives',
        component: pages.CompanyArchives,
        needPermission: true,
        breadcrumb: [
          {
            text: '经营单位档案'
          },
        ],
      },
      {
        path: "/person_archives",
        name: '/person_archives',
        component: pages.PersonArchives,
        needPermission: true,
        breadcrumb: [
          {
            text: '人员档案'
          },
        ],
      },
      {
        path: "/user_manage",
        name: '/user_manage',
        component: pages.Manage.UserManage,
        needPermission: true,
        breadcrumb: [
          {
            text: '系统管理'
          },
          {
            text: '用户管理'
          },
        ],
      },
      {
        path: "/department",
        name: '/department',
        component: pages.Manage.Department,
        needPermission: true,
        breadcrumb: [
          {
            text: '系统管理'
          },
          {
            text: '部门管理'
          },
        ],
      },
      {
        path: "/home",
        name: '/home',
        component: pages.Home,
        needPermission: true,
        breadcrumb: [
          {
            text: '首页'
          },
        ],
      },
      {
        path: "/errorTest",
        name: '/errorTest',
        fixedTheme: 'blue',
        component: pages.ErrorTest,
        breadcrumb: [
          {
            text: '错误页面跳转'
          },
        ],
      },
      {
        path: "/403",
        name: '/403',
        component: pages._403,
        breadcrumb: [
          {
            text: "403"
          },
        ]
      },
      {
        path: "/404",
        name: '/404',
        component: pages._404,
        breadcrumb: [
          {
            text: "404"
          },
        ]
      },
    ]
  },
];

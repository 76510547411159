import React, { useEffect, useRef, useState } from 'react'
import { Link } from "react-router-dom";
import { Menu } from '@yisa/webui';
import { useSelector } from "react-redux";


export default (pros) => {

  const {
    type,
    onCollapsed,
    collapsed
  } = pros

  const isFirst = useRef(true)

  const [openKeys, setOpenKeys] = useState([])

  const menus = useSelector((state) => {
    return state.user.menus
  });

  const activeKey = useSelector((state) => {
    return state.comment.routerData.name
  });

  useEffect(() => {
    if (isFirst.current && activeKey && menus.length) {
      isFirst.current = false
      try {
        menus.forEach((item, index) => {
          if (item.children && item.children.length) {
            item.children.forEach((item2) => {
              if (item2.path == activeKey) {
                setOpenKeys([item.path])
              }
              if (item2.children && item2.children.length) {
                item2.children.forEach((item3) => {
                  if (item3.path == activeKey) {
                    setOpenKeys([item.path])
                  }
                })
              }
            })
          }
        })
      } catch (error) {
      }
    }
  }, [activeKey])

  return <Menu
    openKeys={openKeys}
    data={menus}
    type={type}
    link={Link}
    onCollapsed={onCollapsed}
    inlineCollapsed={collapsed}
    activeKey={activeKey} />
}

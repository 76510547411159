import React, { useState, useEffect, useRef } from 'react';
import { Table, Button, Pagination, Message, PopConfirm, Image } from '@yisa/webui';
import { FormDatePicker, BigImgSwitch, ResultFlow, FormInput, FormTreeSelect, FormSelect, ExportModal } from '@/components';
import { useBoolean } from "ahooks";
import { useSelector } from "react-redux";
import { character } from "@/utils";
import DetailModal from './DetailModal'
import errorPng from "@/assets/images/error.png"
import ajax from '@/services';
import './index.scss'

function WarnJudge(props) {

  // 原始表单
  const defaultFormData = {
    // 预警单位
    business_id: [],
    //经营单位名类别
    business_type: 0,
    // 经营单位联系人
    business_person: "",
    // 经营单位联系方式
    business_tel: "",
    // 监管单位
    supervisory_unit: "",
    // 监管单位负责人
    supervisory_person: "",
    // 监管单位联系方式
    supervisory_tel: "",
    // 违规行为
    violation: 0,
    // 任务编号
    task_number: "",
    // 整改状态
    rectification_state: "-1",
    // 预警设备
    device_id: [],
    // 开始结束时间
    start_time: "",
    end_time: "",
    // 页码
    page: 1,
    // 数据量
    page_size: character.pageSizeOptions[0],
  }
  const userInfo = useSelector((state) => {
    return state.user.userInfo
  })
  const [formData, setFormData] = useState(defaultFormData)
  const [lastFormData, setLastFormData] = useState(formData)
  const [loading, { setTrue, setFalse }] = useBoolean(false)
  // 导出
  const [exportVisible, setExportVisible] = useState(false)
  // 经营单位列表
  const [businessList, setBusinessList] = useState([])
  // 抓拍设备列表
  const [devisceList, setDevisceList] = useState([])
  // 违规行为
  const [violationType, setViolationType] = useState([])
  // 表单修改
  const changeValue = (v, k) => {
    setFormData(Object.assign({}, formData, { [k]: v }))
  }
  const [bigImgData, setBigImgData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [bigImgVisible, setBigImgVisible] = useState(false);

  const [detailvisible, setDetailvisible] = useState({
    visible: false,
    data: {}
  })
  const [tableScrollY, setTableScrollY] = useState(200);

  const [resultData, setResultData] = useState({
    list: [],
    total: 0
  })
  const columns = [
    {
      title: "任务编号",
      dataIndex: "task_number",
      align: "center",
    },
    {
      title: "预警单位",
      dataIndex: "business_name",
      align: "center",
    },
    {
      title: "违规行为",
      dataIndex: "violation",
      align: "center",
    },

    {
      title: "预警设备",
      dataIndex: "warn_device",
      align: "center",
    },
    {
      title: "经营单位联系人",
      dataIndex: "business_person",
      width: 90,
      align: "center",
    },
    {
      title: "经营单位联系方式",
      dataIndex: "business_tel",
      align: "center",
    },
    {
      title: "整改状态",
      dataIndex: "rectification_state",
      align: "center",
      render: (text) => {
        return <div className={`state state${text}`}>
          {text == 0 ? "待反馈" : text == 1 || text == 5 ? "待确认" : text == 2 ? "待审核" : text == 3 ? "已完成" : "待反馈"}
        </div>
      }
    },
    {
      title: "预警时间",
      dataIndex: "warn_time",
      align: "center",
    },
    {
      title: "预警图片",
      dataIndex: "warn_img",
      align: "center",
      render: (text) => {
        let src = text ? text.split(";")[0] : errorPng
        return <div className='warn_img'>
          <Image src={src} errorSrc={errorPng}
            onClick={() => {
              setBigImgVisible(true);
              setCurrentIndex(0);
              setBigImgData(text.split(";"))
            }} />
        </div>
      }
    },
    {
      title: "操作",
      dataIndex: "capture_correct_num",
      align: "center",
      render: (text, record) => {
        return <>
          <span className="edit-btn" onClick={() => {
            setDetailvisible({
              visible: true,
              data: {
                ...record,
                warn_img: record.warn_img.split(";"),
                feedback_image: record.feedback_image ? record.feedback_image.split(";") : []
              }
            })
          }}>查看</span>

          {
            userInfo.user_type === 2 && (record.rectification_state === 1 || record.rectification_state === 5) ? <PopConfirm
              title={<span>是否确认这条预警？</span>}
              placement="left"
              onConfirm={() => handleChange(3, record)}
            >
              <span className="edit-btn">确认</span>
            </PopConfirm> : null
          }
          {
            userInfo.user_type === 3 && record.rectification_state === 2 ? <PopConfirm
              title={<span>是否审核通过这条预警？</span>}
              placement="left"
              okText="通过"
              cancelText="驳回"
              onCancel={() => handleChange(1, record)}
              onConfirm={() => handleChange(3, record)}
            >
              <span className="edit-btn">审核</span>
            </PopConfirm> : null
          }
        </>
      }
    }
  ]  // 审核驳回确认
  const handleChange = (type, data) => {
    ajax
      .warningProcess({ id: data.id, violation_id: data.violation_id, status: type, business_type: data.business_type })
      .then((res) => {
        const { status, message, data } = res;
        if (status === 20000) {
          // setResData(data);
          getList(lastFormData)
        } else {
          Message.error(message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // 获取列表数据
  const getList = (params = formData) => {
    setTrue(true)
    setLastFormData(params)
    ajax.getWarningList(params).then(res => {
      if (res.status === 20000) {
        setResultData(res.data)
      } else {
        Message.error(res.message)
      }
      setFalse(false)
    }).catch(err => console.log(err))
  }

  // 获取接收部门
  const getBusinessList = () => {
    ajax
      .getBusiness()
      .then((res) => {
        const { status, message, data } = res;
        if (status === 20000) {
          let arr = [{ label: "全部", value: "-1", children: data }]
          setBusinessList(arr);
        } else {
          Message.error(message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // 获取设备
  const getDeviceList = () => {
    ajax
      .getDeviceCapture()
      .then((res) => {
        const { status, message, data } = res;
        if (status === 20000) {
          let arr = [{ label: "全部", value: "-1", children: data }]
          setDevisceList(arr);
        } else {
          Message.error(message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // 违规行为
  const getViolationType = () => {
    ajax
      .getViolationType()
      .then((res) => {
        const { status, message, data } = res;
        if (status === 20000) {
          setViolationType(data);
        } else {
          Message.error(message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getBusinessList()
    getDeviceList()
    getViolationType()
    getList(formData)
  }, [])

  const reset = () => {
    setFormData(defaultFormData)
    getList(defaultFormData)
  }
  useEffect(() => {
    const handleCalcHeight = () => {
      try {
        const resultHeight = document.querySelector(".table-box")
          ? document.querySelector(".table-box").clientHeight - 90
          : 0;
        const thHeight = document.querySelector(".ysd-table-header")
          ? document.querySelector(".ysd-table-header").clientHeight
          : 0;
        const toolHeight = 32 + 20;
        const height = resultHeight - thHeight - toolHeight;
        setTableScrollY(Math.max(height, 200));
      } catch (err) {
        console.log(err);
      }
    };
    handleCalcHeight();
    window.addEventListener("resize", handleCalcHeight);

    return () => {
      window.removeEventListener("resize", handleCalcHeight);
    };
  }, [resultData.total]);
  return <div className="warnJudge-page">
    <div className="search">
      <div className="title">查询条件</div>

      <FormTreeSelect
        labelPosition="top"
        yisaLabel="预警单位"
        placeholder="请选择预警单位"
        className="search-item"
        value={formData.business_id}
        onChange={(v) => changeValue(v, "business_id")}
        fieldNames={{ title: "label", key: "value" }}
        treeData={businessList}
      />

      <FormSelect
        labelPosition="top"
        yisaLabel="经营单位类别"
        allowClear={true}
        options={[{
          label: "农贸市场",
          value: "1",
        }, {
          label: "液化气单位",
          value: "2",
        }]}
        showUnlimit={true}
        className="search-item"
        placeholder="请选择经营单位类别"
        value={formData.business_type}
        onChange={(v) => changeValue(v, "business_type")}
      />

      <FormInput
        className="search-item"
        yisaLabel="经营单位联系人"
        placeholder="请输入经营单位联系人"
        value={formData.business_person}
        onChange={(v) => changeValue(v.target.value, "business_person")}
      />
      <FormInput
        className="search-item"
        yisaLabel="经营单位联系方式"
        placeholder="请输入经营单位联系方式"
        value={formData.business_tel}
        onChange={(v) => changeValue(v.target.value, "business_tel")}
      />
      <FormInput
        className="search-item"
        yisaLabel="监管单位"
        placeholder="请输入监管单位"
        value={formData.supervisory_unit}
        onChange={(v) => changeValue(v.target.value, "supervisory_unit")}
      />
      <FormInput
        className="search-item"
        yisaLabel="监管单位负责人"
        placeholder="请输入监管单位负责人"
        value={formData.supervisory_person}
        onChange={(v) => changeValue(v.target.value, "supervisory_person")}
      />
      <FormInput
        className="search-item"
        yisaLabel="监管单位联系方式"
        placeholder="请输入监管单位联系方式"
        value={formData.supervisory_tel}
        onChange={(v) => changeValue(v.target.value, "supervisory_tel")}
      />
      <FormSelect
        labelPosition="top"
        yisaLabel="违规行为"
        showClose
        options={violationType}
        className="search-item"
        placeholder="请选择违规行为"
        value={formData.violation}
        onChange={(v) => changeValue(v, "violation")}
      />
      <FormInput
        className="search-item"
        yisaLabel="任务编号"
        placeholder="请输入任务编号"
        value={formData.task_number}
        onChange={(v) => changeValue(v.target.value, "task_number")}
      />
      <FormSelect
        labelPosition="top"
        yisaLabel="整改状态"
        allowClear={true}
        options={[{
          label: "待反馈",
          value: "0",
        }, {
          label: "待确认",
          value: "1",
        }, {
          label: "待审核",
          value: "2",
        }, {
          label: "完结",
          value: "3",
        }]}
        className="search-item"
        showUnlimit={true}
        placeholder="请选择整改状态"
        value={formData.rectification_state}
        onChange={(v) => changeValue(v, "rectification_state")}
      />

      <FormTreeSelect
        labelPosition="top"
        yisaLabel="预警设备"
        placeholder="请选择预警设备"
        className="search-item"
        value={formData.device_id}
        onChange={(v) => changeValue(v, "device_id")}
        fieldNames={{ title: "label", key: "value" }}
        treeData={devisceList}
      />
      <FormDatePicker
        className="search-item"
        labelPosition="top"
        format="YYYY-MM-DD HH:mm:ss"
        showTime={true}
        yisaLabel="开始时间"
        value={formData.start_time}
        onChange={(e) => changeValue(e, "start_time")}
      />
      <FormDatePicker
        className="search-item"
        labelPosition="top"
        format="YYYY-MM-DD HH:mm:ss"
        showTime={true}
        yisaLabel="结束时间"
        value={formData.end_time}
        onChange={(e) => changeValue(e, "end_time")}
      />
      <div className="search-btn-box">
        <Button className="reset-btn" onClick={reset} >
          重置
        </Button>
        <Button type="primary" className='search-btn' onClick={() => getList(formData)}>
          搜索
        </Button>

      </div>
    </div>
    <div className="table-box">
      <nav className="total">
        <p>
          共<span> {resultData.total || 0} </span>条结果
        </p>
      </nav>

      <ResultFlow
        loading={loading}
        resultData={resultData.list}
      >
        <div className="list">
          <Table
            className="table-warp"
            columns={columns}
            data={resultData.list}
            rowKey="id"
            bordered
            scroll={{
              y: tableScrollY
            }}
            loading={loading}
            pagination={false}
          />
        </div>
        <Pagination
          className="pagination-common"
          showQuickJumper
          showSizeChanger
          size='default'
          position={['bottomCenter']}
          current={lastFormData.page}
          total={resultData.total}
          pageSize={lastFormData.page_size}
          onChange={(page, pageSize) => {
            let obj = {};
            if (pageSize !== lastFormData.page_size) {
              obj.page = 1
              obj.page_size = pageSize
            } else {
              obj.page = page
            }
            let newParams = Object.assign({}, lastFormData, obj);
            getList(newParams)
          }}
          pageSizeOptions={character.pageSizeOptions}
        />
      </ResultFlow>
    </div>
    <ExportModal
      total={resultData?.total}
      formData={{ ...lastFormData, page: "", page_size: "" }}
      visible={exportVisible}
      onCancel={() => { setExportVisible(false) }}
      url='/warning/export'
      ajaxType="post"
    />
    <DetailModal
      visible={detailvisible.visible}
      data={detailvisible.data}
      onCancel={() => {
        getList(lastFormData)
        setDetailvisible({
          visible: false,
          data: {}
        })
      }}
      onClose={()=>{
        setDetailvisible({
          visible: false,
          data: {}
        })
      }}
    />
    <BigImgSwitch
      visible={bigImgVisible}
      currentIndex={currentIndex}
      onCancel={() => setBigImgVisible(false)}
      yisaData={bigImgData}
      modalWidth={1400}
      changeNext={(v) => {
        setCurrentIndex(v);
      }}
      changePrev={(v) => {
        setCurrentIndex(v);
      }}
    />
  </div>
}

export default WarnJudge
